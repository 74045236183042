<template>
  <el-container>
    <el-main class="c-orders">
      <div class="title">Ordres de sous-titrage en français</div>

      <el-form @submit.native.prevent="handleGetList" :class="$store.getters.isAdmin ? 'admin' : ''">
        <el-input placeholder="Mot-clé" v-model="searchText" autofocus />
        <el-button-group>
          <el-tooltip content="Lancer la recherche" placement="top" effect="light">
            <el-button @click="handleGetList" type="primary" plain icon="el-icon-search"></el-button>
          </el-tooltip>
          <el-tooltip content="Effacer le mot-clé" placement="top" effect="light">
            <el-button @click="handleResetForm" icon="el-icon-delete"></el-button>
          </el-tooltip>
        </el-button-group>
      </el-form>

      <el-table
        ref="table"
        v-loading="isLoading"
        :data="orders"
        :default-sort="tableSort"
        :empty-text="emptyText"
        @sort-change="handleSortChange"
        height="calc(100vh - 295px)"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.details" style="width: 100%">
              <el-table-column prop="user" label="Utilisateur"></el-table-column>
              <el-table-column prop="total" label="Ordres totaux"></el-table-column>
              <el-table-column prop="totalDuration" label="Durée totale"></el-table-column>

              <!-- TODO: detailed view per user of all orders in a period -->
              <!-- <el-table-column>
                <el-button icon="el-icon-document"></el-button>
              </el-table-column>-->
            </el-table>
          </template>
        </el-table-column>
        <el-table-column sortable label="Période" prop="period">
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.period) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Ordres totaux" prop="total"></el-table-column>
        <el-table-column label="Durée totale" prop="totalDuration"></el-table-column>
      </el-table>
      <div class="footer">
        <div class="total">{{ total }} fichiers trouvés</div>
        <div class="paging">
          <el-pagination
            layout="prev, pager, next"
            :hide-on-single-page="true"
            :page-size="pageSize"
            :total="total"
            :current-page.sync="page"
            @current-change="handlePaging"
          />
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getOrders } from '@/api'
import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'

export default {
  name: 'Orders',
  components: {},
  data: () => ({
    isLoading: false,
    searchText: '',
    orders: [],
    defaultSort: '-period',
    sort: '-period',
    page: 1,
    pageSize: 20,
    total: 0
  }),
  created() {
    this.handleGetList()
  },
  computed: {
    emptyText() {
      return this.searchText.length > 0 ? "Le terme recherché n' a pas été trouvé. Essayez à nouveau avec un autre terme." : 'Aucune données'
    },
    tableSort() {
      const [, , direction, prop] = /((-?)([^, ]+))+/i.exec(this.sort)
      return { prop, order: direction === '-' ? 'descending' : 'ascending' }
    }
  },
  methods: {
    async handleGetList() {
      this.isLoading = true
      try {
        var res = await getOrders(this.searchText, this.sort, this.page, this.pageSize)
        const { items, itemsCount, page } = await res.json()
        this.orders = items
        this.page = page
        this.total = itemsCount
      } catch (e) {
        this.orders = []
        this.page = 1
        this.total = 0
      } finally {
        this.isLoading = false
      }
    },
    getTableIndex(index) {
      return (this.page - 1) * this.pageSize + index + 1
    },
    async handleResetForm() {
      this.searchText = ''
      this.sort = this.defaultSort
      this.page = 1
      this.$refs.table.clearSort()
      await this.handleGetList()
    },
    async handlePaging(page) {
      this.page = page
      this.handleGetList()
    },
    handleSortChange({ prop, order }) {
      if (!order) this.sort = this.defaultSort
      else this.sort = (order === 'descending' ? '-' : '') + prop
      this.handleGetList()
    },
    formatDate(date) {
      return format(new Date(date), 'MMMM yyyy', { locale: fr })
    }
  }
}
</script>

<style lang="scss">
.c-orders {
  height: calc(100vh - 90px);

  .title {
    font-size: 19px;
    font-weight: 400;
    margin: 10px 0 30px 3px;
  }

  .el-form {
    display: grid;
    grid-template-columns: auto 115px;
    column-gap: 10px;
    justify-items: right;
    margin-bottom: 20px;
  }
  .footer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    height: 30px;
    > .total {
      line-height: 34px;
      color: gray;
      font-size: 0.9em;
    }
    .paging {
      > .el-pagination {
        display: table;
        margin: 0 auto;

        button,
        .el-pager li {
          background: transparent;
        }
      }
    }
  }
}
</style>
